<template>  
    <mbs-page-spinner v-if="false" />  
    <v-card outlined id="print_template" width="100%" v-else>
        <v-card-text class="mb-9 px-6 py-10">
            <v-layout justify-center align-center class="mt-9 mb-9"> 
                <div class="text-center py-5"> 
                    <div class="text-center font-weight-bold not-f5">{{MBS.actions.TEXT_UP(JoinedInvoice.company_name)}}</div>  
                    <div class="text-center font-weight-bold not-f3">Phone: {{JoinedInvoice.company.company_phone_number}}</div>  
                    <div class="text-center not-f3">Email: {{JoinedInvoice.company.company_email_address}}</div>  
                    <div class="text-center not-f3">Location Address: {{JoinedInvoice.company.company_location_address}}</div>  
                     
                    <div>{{MBS.date.moment().format('dddd, Do, MMMM YYYY')}}</div> 
                </div>
            </v-layout> 
            <v-card color="black" flat outlined >
                <v-card flat class="pa-3 ma-1 text-center font-weight-bold not-f5">
                    <div>INVOICE PAYMENTS STATEMENT</div>
                </v-card>
            </v-card>
            <br>  
            <v-list>
                <v-list-item class="ma-0  font-weight-bol ">
                    <v-list-item-content>
                        <div class=" not-f">Invoice No: {{JoinedInvoice.key}}</div>
                        <div class="not-f">Date: {{JoinedInvoice.created_at_}}</div>
                    </v-list-item-content>
                    <v-list-item-action>
                        <div class="not-f">Bill To: {{JoinedInvoice.customer_name}}</div>
                        <div class="not-f">Account: {{JoinedInvoice.receivable_name}}</div>
                    </v-list-item-action>
                </v-list-item> 
            </v-list>
            <v-divider></v-divider>
            <v-list-item>
                <v-list-item-content>REVENUE</v-list-item-content>
                <v-list-item-action>TOTAL (MWK)</v-list-item-action>
            </v-list-item>
            <hr> 
            <v-list>
                <v-list-item class="font-weight-bold">
                    <v-list-item-content>TOTAL INVOICE AMOUNT</v-list-item-content>
                    <v-list-item-action>{{MBS.actions.money(TotalPrice)}}</v-list-item-action>
                </v-list-item> 
            </v-list>
            <v-list-group
                v-model="expend_expenses"
                :no-action ="true" 
                :sub-group ="false" 
                append-icon="" 
                >
                <template v-slot:activator class="ma-0 pa-0"> 
                    <v-list-item-action class="ma-0 pa-0">
                        <v-icon class="pr-2" v-if="expend_expenses">expand_more</v-icon>
                        <v-icon class="pr-2" v-else>chevron_right</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Payments</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action v-if="!expend_expenses">
                        {{MBS.actions.money(TotalPayments)}}
                    </v-list-item-action>
                </template>

                <v-list-item class="pl-13"
                    v-for="(payment, i) in InvoicePayments"
                    :key="i"
                    link >
                    <v-list-item-title >{{payment.created_at_}}</v-list-item-title> 
                    <v-list-item-action> {{MBS.actions.money(payment.amount)}} </v-list-item-action>
                </v-list-item>
                <v-divider class="ml-13"></v-divider>
                <v-list-item class="font-weight-bold pl-13">
                    <v-list-item-content>Total Payments</v-list-item-content>
                    <v-list-item-action>{{MBS.actions.money(TotalPayments)}}</v-list-item-action>
                </v-list-item>
            </v-list-group>
            <br>
            <hr>
            <v-list-item class="font-weight-bold">
                <v-list-item-content>BALANCE</v-list-item-content>
                <v-list-item-action>{{MBS.actions.money(TotalPrice-TotalPayments)}}</v-list-item-action>
            </v-list-item> 
            <br> 
        </v-card-text> 
    </v-card>
</template>

<script>
    import DATA from "../../../plugins/DATA"
    import {mapState,mapGetters } from "vuex"
    let NAME = 'DIALOG'
    export default {
        props:['data','sale_orders','expenses_accounts'], 
        data(){
            return{
                show:true,
                DATA:DATA,
                printSVG:null,
                inputLoading:true,
                expend_expenses:true,
            } 
        }, 
        mounted(){ 
        },
        computed:{
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Items: state=> state.items[(DATA.ITEMS.ITEMS.values).toUpperCase()],
                JoinedItems: state=> state.join[(DATA.ITEMS.ITEMS.values).toUpperCase()], 
                Payments: state=> state.items[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],
                JoinedPayments: state=> state.join[(DATA.ITEMS.PAYMENTS.values).toUpperCase()],  
            }), 
            DataPayments(){
                let Payments = this.Payments
                let JoinedPayments = this.JoinedPayments 
                return JoinedPayments?JoinedPayments:Payments
            },
            vs(){
                return this.$vuetify.breakpoint
            }, 
            vsh(){
                return this.vs.height
            },    

            InvoicePayments(){
                let payments = this.DataPayments
                let Invoice = this.Invoice
                if(!payments||!Invoice){return null}
                let filled_items = payments.filter(item=>{
                    return item.transaction_key == Invoice.key
                })
                return filled_items
            },
            Invoice(){ 
                let data = this.data
                return  data
            },
            JoinedInvoice(){ 
                let item = this.Invoice 
                let joined = this.MBS.actions.JOIN_SALE_ORDER(item)  
                return joined
            }, 
            TotalPrice(){
                try { 
                    let items = this.JoinedInvoice?this.JoinedInvoice.joined_items:null
                    let total_price = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let sold_price = this.toNumber(item.sold_price)
                        let discount = this.toNumber(item.discount)
                        let quantity = this.toNumber(item.quantity)
                        total_price = total_price + (quantity*(sold_price-discount))
                    });  
                    return total_price
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPrice',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            }, 
            TotalPayments(){
                try { 
                    let items = this.InvoicePayments
                    let total_amount = 0 
                    if (!items) {return 0}
                    items.forEach(item => {
                        let amount = this.toNumber(item.amount) 
                        total_amount = total_amount + amount
                    });  
                    return total_amount
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TotalPayments',
                        page:PAGE_NAME, 
                    }) 
                    return 0
                }  
            }, 
        },
        methods:{
            CALCULATE_PRICE(item){
                try { 
                    if (!item) {return 0}
                    let sold_price = this.toNumber(item.sold_price)
                    let discount = this.toNumber(item.discount)
                    let quantity = this.toNumber(item.quantity)
                    return quantity*(sold_price-discount)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'CALCULATE_PRICE',
                        page:PAGE_NAME, 
                    }) 
                }
                
            },   
        },
        watch:{
            mbs_dialog(value){ 
            },   
        } 
    }
</script>
